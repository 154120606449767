<template>
  <div class="view-wrap">
    <el-form :model="searcForm" class="F">
      <el-form-item label="" style="width: 230px; margin-right: 20px">
        <el-input
          v-model="searchForm.param"
          placeholder="输入往来单位名称/编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型:" style="width: 270px">
        <el-select v-model="searcForm.type" placeholder="" clearable>
          <el-option label="全部" :value="0"> </el-option>
          <el-option label="客户" :value="1"> </el-option>
          <el-option label="供应商" :value="2"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="显示应收/应付为零的单位:" style="width: 430px">
        <el-select v-model="searcForm.isZero" placeholder="" clearable>
          <el-option label="是" :value="1"> </el-option>
          <el-option label="否" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <div class="F">
          <el-button type="primary" @click="searchForm">查询</el-button>
          <el-button type="primary" @click="handleDownload" plain
            >导出</el-button
          >
        </div>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      show-summary
      :summary-method="getSummaries"
      style="width: 100%"
    >
      <el-table-column label="操作" width="100">
        <template>
          <el-button type="text">查看明细</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="往来单位编号"></el-table-column>
      <el-table-column prop="name" label="往来单位名称"></el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          {{scope.row.type}}
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="应收款余额">
        <template slot-scope="scope">
          <span v-if="scope.row.type == '客户'">{{ scope.row.balance?scope.row.balance.toFixed(2):"" }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="应付款余额">
        <template slot-scope="scope">
          <span v-if="scope.row.type == '供应商'">{{ scope.row.balance?scope.row.balance.toFixed(2):"" }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getUnitAll,} from '@/api/finance'
export default {
  data() {
    return {
      searcForm: {
        param: "",
        isZero: 1,
        type: 0,
      },
      tableData: [],
    };
  },
  created() {
    this.getUnitAll();
  },
  methods: {
    async getUnitAll(){
        let params = this.searcForm;
        let res = await getUnitAll(params);
        if(res.code == 1){
            this.tableData = res.data;
        }
    },
    searchForm() {},
    handleDownload() {},
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = "总价";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += " 元";
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
};
</script>

<style lang='scss' scoped>
.F {
  display: flex;
}
</style>